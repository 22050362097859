<template>
    <frontoffice-layout :title="$t('my_profile')">       
        <section class="shell">
            <profile-form />
        </section>
    </frontoffice-layout>
</template>

<script>
import profileForm from '@/patterns/profileForm';

export default {
    components: {
        profileForm
    }
}
</script>
